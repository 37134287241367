import React from "react";

import image7 from "../assests/gokhda.webp";
import { IoIosCheckmarkCircle } from "react-icons/io";
import decoration from "../assests/decoration.png";
import { Zoom } from "react-awesome-reveal";

const Testimonials = () => {
  const planningData = [
    "Manage and develop custom decor",
    "Innovative and experiential details",
    "Vendor contracts and negotiations",
    "Event coordination packages",
  ];
  // const gridItems = [
  //   {
  //     img: "http://www.swaytheme.com/wp-content/uploads/sites/3/2020/11/wedding-icon5.png",
  //     title: "WEDDING",
  //     content: "We create unforgettable memories & experiences",
  //   },
  //   {
  //     img: "http://www.swaytheme.com/wp-content/uploads/sites/3/2020/11/wedding-icon2.png",
  //     title: "CATERING",
  //     content: "We create unforgettable memories & experiences",
  //   },
  //   {
  //     img: "http://www.swaytheme.com/wp-content/uploads/sites/3/2020/11/wedding-icon3.png",
  //     title: "PLANNING",
  //     content: "We create unforgettable memories & experiences",
  //   },
  //   {
  //     img: "http://www.swaytheme.com/wp-content/uploads/sites/3/2020/11/wedding-icon4.png",
  //     title: "PRODUCTION",
  //     content: "We create unforgettable memories & experiences",
  //   },
  // ];
  return (
    <>
      <div className="mt-[9rem]">
        <p className="main-heading text-center m-2 font-semibold text-[#d0a37c] ">
          A LUXURY EVENT PLANNING, DESIGN AND MANAGEMENT COMPANY IN MIDST OF
          CITY OF LAKES
        </p>
        <h2 className="main-heading2 text-center text-4xl mt-4 text-[#1e266d]">
          WE WORK WITH YOU TO DECOR YOUR PERFECT EVENT
        </h2>
      </div>
      <div
        className={`flex lg:gap-10 xl:gap-[6rem] gap-[2rem] justify-center flex-wrap	mt-16 xl:mt-28`}
      >
        <div>
          <Zoom triggerOnce>
            <img
              src={image7}
              className="xl:w-[28rem] w-[23rem] xl:m-[0.5rem] rounded-lg"
              // loading="lazy"
              alt="Raffles"
            />
          </Zoom>
        </div>
        <div className="m-6">
          <h1 className="main-heading2 md:text-[2rem] sm:text-[1.6rem] text-[#1e266d]">
            WE BELIEVE IN A UNIQUE & <br className="break-desktop" />
            COLLABORATIVE APPROACH
          </h1>
          <p className="mt-2 text-[#515151] text-[1rem] font-semibold py-8 ">
            We believe in a collaborative approach, walking you{" "}
            <br className="break-desktop" /> through the planning process with
            ease, offering <br className="break-desktop" />
            planning, coordination and management.
          </p>
          {planningData.map((item, idx) => (
            <ul
              key={idx}
              className="mt-4 flex mb-[1rem] flex-wrap gap-[10px] items-center"
            >
              <IoIosCheckmarkCircle />
              <li className="text-[#1e266d] font-semibold">{item}</li>
            </ul>
          ))}
        </div>
      </div>
      <div className="relative  xl:mt-[6rem] mt-[4rem]">
        <div className="relative w-full h-[80vh]">
          <img
            src={decoration}
            className="w-full h-full object-cover"
            alt="Decorative Image"
          />
          <div className="absolute inset-0 bg-black opacity-50"></div>
          <div className="absolute  inset-0 m-[3rem] lg:m-[8rem] flex justify-center items-center">
            <div className="text-center text-white">
              <p className="main-heading2 lg:text-[39px] text-[26px]">
                A FULL PRODUCTION WEDDING AND EVENT{" "}
                <br className="break-desktop2" /> PLANNING COMPANY IN UDAIPUR
                CITY
              </p>
              <p className="lg:text-[1.3rem] md:text-[1rem] mt-10">
                Our staff is experienced in producing destination events around
                the Udaipur City.
                <br className="break-desktop3" />
                we make planning a destination event enjoyable.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="flex xl:mt-[-10rem] justify-center">
        <div className="mb-[2rem] m-[1rem] grid sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-4 place-items-center">
          {gridItems.map((item, index) => (
            <Zoom damping={0.1} cascade triggerOnce>
              <div
                key={index}
                className="flex flex-col items-center gap-[2rem] p-[3rem] border border-[#e7e7e7] xl:w-[20rem] py-[3rem] justify-center bg-white hover:-translate-y-3"
              >
                <img src={item.img}></img>
                <h2 className="text-[#1e266d] text-[22px] text-center">
                  {item.title}
                </h2>
                <p className="text-center text-[#455880]">{item.content}</p>
              </div>
            </Zoom>
          ))}
        </div>
      </div> */}
    </>
  );
};

export default Testimonials;
