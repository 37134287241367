import image1 from "../assests/gall/gallery1.webp";
import image2 from "../assests/gall/gallery2.webp";
import image3 from "../assests/gall/gallery3.webp";
import image4 from "../assests/gall/gallery4.webp";
import image5 from "../assests/gall/gallery5.webp";
import image6 from "../assests/gall/gallery6.webp";
import image7 from "../assests/gall/gallery7.webp";
import image8 from "../assests/gall/gallery8.webp";
import image9 from "../assests/gall/gallery9.webp";
import image10 from "../assests/gall/gallery10.webp";
import image11 from "../assests/gall/gallery11.webp";
import image12 from "../assests/gall/gallery12.webp";

export const galleryData = [
  {
    img: image1,
    style: {
      width: "100%",
      height: "450px",
      objectFit: "cover",
      borderRadius: "0.5rem",
    },
    title: "COUPLE STAGE",
    // genre: "Wedding Dress",
    margin: "0px 40px 0px 0px",
  },
  {
    img: image2,
    style: {
      width: "100%",
      height: "235px",
      objectFit: "cover",
      borderRadius: "0.5rem",
    },
    margin: "0px 20px 0px 20px",
    title: "GALLERY WALKWAY",
    // genre: "Wedding Dress",
  },
  {
    img: image3,
    style: {
      width: "100%",
      height: "450px",
      objectFit: "cover",
      borderRadius: "0.5rem",
    },
    title: "PASTEL HALDI",
    //: "Photography",
    margin: "0px 0px 0px 40px",
  },
  {
    img: image4,
    style: {
      width: "100%",
      height: "235px",
      objectFit: "cover",
      marginTop: "50px",
      borderRadius: "0.5rem",
    },
    margin: "0px 40px 0px 0px",
    title: "STARRY SANGEET ",
    // //: "Wedding Dress",
  },
  {
    img: image5,
    style: {
      width: "100%",
      height: "450px",
      objectFit: "cover",
      marginTop: "50px",
      borderRadius: "0.5rem",
    },
    margin: "-205px 20px 0px 20px",
    title: "BIRTHDAY BASH",
    //: "Wedding Dress",
  },
  {
    img: image6,
    style: {
      width: "100%",
      height: "235px",
      objectFit: "cover",
      marginTop: "50px",
      borderRadius: "0.5rem",
    },
    margin: "0px 0px 0px 40px",
    title: "AESTHETIC WALKWAY MANDAP",
    //: "Photography",
  },
  {
    img: image7,
    style: {
      width: "100%",
      height: "450px",
      objectFit: "cover",
      marginTop: "2.5rem",
      borderRadius: "0.5rem",
    },
    title: "PINK FLORAL GALLERY",
    //: "Wedding Dress",
    margin: "0px 40px 0px 0px",
  },
  {
    img: image8,
    style: {
      width: "100%",
      height: "235px",
      objectFit: "cover",
      marginTop: "2.5rem",
      borderRadius: "0.5rem",
    },
    margin: "0px 20px 0px 20px",
    title: "SANGEET ENTRANCE",
    //: "Wedding Dress",
  },
  {
    img: image9,
    style: {
      width: "100%",
      height: "450px",
      objectFit: "cover",
      marginTop: "2.5rem",
      borderRadius: "0.5rem",
    },
    title: "BANQUET WEDDING",
    //: "Photography      ",
    margin: "0px 0px 0px 40px",
  },
  {
    img: image10,
    style: {
      width: "100%",
      height: "235px",
      objectFit: "cover",
      marginTop: "50px",
      borderRadius: "0.5rem",
    },
    margin: "0px 40px 0px 0px",
    title: "RECEPTION STAGE",
    //: "Wedding Dress",
  },
  {
    img: image11,
    style: {
      width: "100%",
      height: "450px",
      objectFit: "cover",
      marginTop: "50px",
      borderRadius: "0.5rem",
    },
    margin: "-205px 20px 0px 20px",
    title: "CLASSIC MEHENDI",
    //: "Wedding Dress",
  },
  {
    img: image12,
    style: {
      width: "100%",
      height: "235px",
      objectFit: "cover",
      marginTop: "50px",
      borderRadius: "0.5rem",
    },
    margin: "0px 0px 0px 40px",
    title: "MEHENDI COUPLE SITTING",
    //: "Photography",
  },
];
