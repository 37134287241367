// import React, { useState } from "react";
// import { MdMenu, MdLocationPin, MdPhone } from "react-icons/md";
// import { NavLink, Link, useLocation } from "react-router-dom";
// import { VscCircleFilled } from "react-icons/vsc";
// import { Twirl as Hamburger } from "hamburger-react";
// import logo from "../assests/asd-removebg-preview.png";
// import { WavyLink } from "react-wavy-transitions";

// const Navbar = () => {
//   // const [open, setOpen] = useState(false);
//   const location = useLocation();
//   const { pathname } = location;
//   const splitLocation = pathname.split("/");

//   const navItems = [
//     {
//       label: "Home",
//       path: "/",
//       icon: splitLocation[1] === "" ? <VscCircleFilled /> : null,
//       color: "#f8f2bf",
//     },
//     {
//       label: "About us",
//       path: "/about",
//       icon: splitLocation[1] === "about" ? <VscCircleFilled /> : null,
//       color: "#FED917",
//     },
//     {
//       label: " Contact us",
//       path: "/contact",
//       icon: splitLocation[1] === "contact" ? <VscCircleFilled /> : null,
//       color: "#113BBB",
//     },
//   ];
//   let Links = [
//     { name: "HOME", link: "/" },
//     { name: "SERVICE", link: "/" },
//     { name: "ABOUT", link: "/" },
//     { name: "CONTACT", link: "/" },
//   ];
//   let [open, setOpen] = useState(false);
//   return (
//     <div className="shadow-md w-full left-0">
//       <div className="md:flex items-center justify-between bg-white py-4 md:px-10 px-7">
//         {/* logo section */}
//         <div className="font-bold text-2xl cursor-pointer flex items-center justify-between">
//           {/* <BookOpenIcon className="w-7 h-7 text-blue-600" /> */}
//           <p className="logo-header">The Event Code</p>
//           {/* <img src={logo}></img> */}
//           <div className="cursor-pointer md:hidden">
//             <Hamburger size={30} toggled={open} toggle={setOpen} />
//           </div>
//         </div>
//         {/* Menu icon */}
//         {/* <div
//           onClick={() => setOpen(!open)}
//           className="absolute right-8 top-6 cursor-pointer md:hidden w-7 h-7"
//         >
//           {open ? "X" : "-"}
//         </div> */}

//         {/* linke items */}
//         <ul
//           className={`md:flex gap-[2rem] md:items-center md:pb-0  absolute md:static bg-white md:z-auto z-[1200] left-0 w-full md:w-auto md:pl-0 pl-4 transition-all duration-500 ease-in ${
//             open ? "top-14" : "top-[-490px]"
//           }`}
//         >
//           {navItems.map((item, index) => (
//             <li
//               key={index}
//               className="md:ml-6 md:my-0 my-5 flex items-center gap-2"
//             >
//               {item.icon}
//               <WavyLink to={item.path} color={item.color}>
//                 <p className="font-semibold">{item.label}</p>
//               </WavyLink>
//             </li>
//           ))}
//           {/* <button className="btn bg-blue-600 text-white md:ml-8 font-semibold px-3 py-1 rounded duration-500 md:static">
//             Get Started
//           </button> */}
//         </ul>
//         {/* button */}
//       </div>
//     </div>
//   );
// };

// export default Navbar;
import { useLocation, useNavigate } from "react-router-dom";
import { React, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { VscCircleFilled } from "react-icons/vsc";
import { WavyLink } from "react-wavy-transitions";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";

const Navbar = () => {
  const [open, setOpen] = useState(false);

  const toggleMenu = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const color = "#C2B97F";
  const navLinks = [
    {
      label: "Home",
      path: "/",
      icon: splitLocation[1] === "" ? <VscCircleFilled color={color} /> : null,
      activeMarker: splitLocation[1] === "" ? "active" : null,
      color: "#BAB86C",
    },
    {
      label: "About us",
      path: "/about",
      icon:
        splitLocation[1] === "about" ? <VscCircleFilled color={color} /> : null,
      activeMarker: splitLocation[1] === "about" ? "active" : null,
      color: "#fff5d7",
    },
    {
      label: "Gallery",
      path: "/gallery",
      icon:
        splitLocation[1] === "gallery" ? (
          <VscCircleFilled color={color} />
        ) : null,
      activeMarker: splitLocation[1] === "gallery" ? "active" : null,
      color: "#F3F1ED",
    },
    {
      label: "Services",
      path: "/services",
      icon:
        splitLocation[1] === "services" ? (
          <VscCircleFilled color={color} />
        ) : null,
      activeMarker: splitLocation[1] === "services" ? "active" : null,
      color: "#C3D3E0",
    },
    {
      label: " Contact us",
      path: "/contact",
      icon:
        splitLocation[1] === "contact" ? (
          <VscCircleFilled color={color} />
        ) : null,
      activeMarker: splitLocation[1] === "contact" ? "active" : null,
      color: "#113BBB",
    },
  ];

  const menuVars = {
    initial: {
      scaleY: 0,
    },
    animate: {
      scaleY: 1,
      transition: {
        duration: 0.5,
        ease: [0.12, 0, 0.39, 0],
      },
    },
    exit: {
      scaleY: 0,
      transition: {
        delay: 0.5,
        duration: 0.5,
        ease: [0.22, 1, 0.36, 1],
      },
    },
  };
  const containerVars = {
    initial: {
      transition: {
        staggerChildren: 0.09,
        staggerDirection: -1,
      },
    },
    open: {
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.09,
        staggerDirection: 1,
      },
    },
  };

  return (
    <header className="bg-transparent">
      <nav className="flex justify-around items-center py-8 lg:py-4 px-4">
        <div className="flex items-center gap-[1ch]">
          <p
            className="logo-header cursor-pointer"
            onClick={() => {
              navigate("/");
            }}
          >
            The Event Code
          </p>
        </div>
        <div className="lg:flex hidden gap-6 text-md text-zinc-400">
          {navLinks.map((item, index) => (
            <li
              key={index}
              className="md:ml-6 md:my-0 my-5 flex items-center gap-2 "
            >
              {item.icon}
              <WavyLink to={item.path} color={item.color}>
                <p className="text-black nav-head">{item.label}</p>
              </WavyLink>
            </li>
          ))}
        </div>
        <div
          className="cursor-pointer lg:hidden text-md text-black"
          onClick={toggleMenu}
        >
          <GiHamburgerMenu size={30} />
        </div>
      </nav>
      <AnimatePresence>
        {open && (
          <motion.div
            variants={menuVars}
            initial="initial"
            animate="animate"
            exit="exit"
            className="fixed left-0 top-0 w-full h-screen origin-top bg-yellow-400 text-black p-10 z-[1100]"
          >
            <div className="flex h-full flex-col">
              <div className="flex justify-between items-center">
                <p className="logo-header">The Event Code</p>
                <p
                  className="cursor-pointer text-md text-black"
                  onClick={toggleMenu}
                >
                  <AiOutlineClose size={30} />
                </p>
              </div>
              <motion.div
                variants={containerVars}
                initial="initial"
                animate="open"
                exit="initial"
                className="flex flex-col h-full justify-center font-lora items-center gap-4 "
              >
                {navLinks.map((link, index) => {
                  const { label, path, icon, activeMarker, color } = link;
                  return (
                    <div className="overflow-hidden">
                      <MobileNavLink
                        key={index}
                        label={label}
                        path={path}
                        icon={icon}
                        color={color}
                        activeMarker={activeMarker}
                      />
                    </div>
                  );
                })}
              </motion.div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </header>
  );
};

export default Navbar;
const mobileLinkVars = {
  initial: {
    y: "30vh",
    transition: {
      duration: 0.5,
      ease: [0.37, 0, 0.63, 1],
    },
  },
  open: {
    y: 0,
    transition: {
      ease: [0, 0.55, 0.45, 1],
      duration: 0.7,
    },
  },
};
const MobileNavLink = ({ label, path, color, activeMarker }) => {
  return (
    <motion.div
      variants={mobileLinkVars}
      className="text-5xl uppercase text-black"
    >
      <WavyLink to={path} color={color} className="text-5xl">
        <div className={`${activeMarker} text-3xl`}>{label}</div>
      </WavyLink>
    </motion.div>
  );
};
