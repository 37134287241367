import React from "react";
import SideBar from "./SideBar";
import image from "../assests/DECOR 2023_368.jpeg";

const Hero = () => {
  // const handleClick = () => {
  //   console.log("sidebar open");
  // };
  return (
    <div className={`hero-container2`}>
      <section style={{ backgroundImage: `url("${image}")` }}>
        <div className="text-white z-[1] text-center md:mb-[3rem]">
          <h2 className="main-heading2 xl:text-[70px] mb-[1.2rem] lg:text-[50px] md:text-[45px] sm:text-[35px]">
            WEDDING PLANNER & <br />
            EVENT DESIGNERS
          </h2>
          <p className="text-[18px]">
            We have more than 7 years of event-planning experience and handle
            almost everything in-house, from <br className="break-desktop" />
            coordination to floral design to lighting. Full-service and
            individual design packages available.
          </p>
        </div>
        <SideBar showBtn={true} />
        <div className="wave wave1"></div>
        <div className="wave wave2"></div>
        <div className="wave wave3"></div>
        <div className="wave wave4"></div>
      </section>
    </div>
  );
};

export default Hero;
