import React from "react";
import { footerSocialLinks, sections } from "../utils/footerData";
import { motion } from "framer-motion";

const Footer = () => {
  const fadeAnimation = {
    initial: {
      opacity: 0,
      y: 100,
    },
    animate: (index) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.7 * index,
      },
    }),
  };

  return (
    // <footer className="bg-[#12141B] mt-32">
    //   <div className="md:flex flex-wrap lg:justify-around md:w-[50%] mt-4 p-12">
    //     {/* <div className="mb-8">
    //       <h1 className="font-bold text-2xl cursor-pointer flex items-center text-white">
    //         LOGO
    //       </h1>
    //       <p className="text-white mt-6">
    //         Lorem Ipsum is simply dummy text of the
    //         <br /> printing and typesetting industry.
    //       </p>
    //       <p className="text-white mt-6">info@company.com</p>
    //     </div> */}
    //     {sections.map((section, index) => (
    //       <div key={index} className="mb-8">
    //         <h1 className="text-[#C9BDAA] text-[1rem] font-semibold mb-6">
    //           {section.heading}
    //         </h1>
    //         <ul className="leading-7">
    //           {section?.list?.map((item, itemIndex) => (
    //             <motion.li
    //               variants={fadeAnimation}
    //               initial="initial"
    //               whileInView="animate"
    //               viewport={{
    //                 once: true,
    //               }}
    //               custom={itemIndex}
    //               key={itemIndex}
    //               className="text-white py-2"
    //             >
    //               {item}
    //             </motion.li>
    //           ))}
    //         </ul>
    //       </div>
    //     ))}
    //     <div>
    //       <h1 className="text-[#C9BDAA] text-[1rem] font-semibold">
    //         Social Links
    //       </h1>
    //       <div className="flex justify-end items-end gap-4 mt-8 cursor-pointer">
    //         {footerSocialLinks?.map((item, itemIndex) => (
    //           // <div key={itemIndex} className={item.className}>
    //           //   {item.icon}
    //           // </div>
    //           <motion.li
    //             variants={fadeAnimation}
    //             initial="initial"
    //             whileInView="animate"
    //             viewport={{
    //               once: true,
    //             }}
    //             custom={itemIndex}
    //             key={itemIndex}
    //             className={item.className}
    //           >
    //             {item.icon}
    //           </motion.li>
    //         ))}
    //       </div>
    //     </div>
    //   </div>
    //   <div className="h-px w-full bg-[#D2D2D2]"></div>
    //   <p className="text-center text-white text-[12px] p-5">
    //     Copyright ® 2023 Company. All rights Reserved
    //   </p>
    // </footer>
    <footer className="bg-[#12141B]">
      <div className="mx-10 py-10 md:text-left">
        <div className="grid-1 grid gap-10 md:grid-cols-2 xl:grid-cols-4">
          {/* {sections.map((section, index) => (
            <div key={index}>
              <h1 className="text-[#C9BDAA] text-[1rem] font-semibold mb-6">
                {section.heading}
              </h1>
              <ul className="leading-7">
                {section?.list?.map((item, itemIndex) => (
                  <motion.li
                    variants={fadeAnimation}
                    initial="initial"
                    whileInView="animate"
                    viewport={{
                      once: true,
                    }}
                    custom={itemIndex}
                    key={itemIndex}
                    className="text-white py-2 cursor-pointer	"
                  >
                    {item}
                  </motion.li>
                ))}
              </ul>
            </div>
          ))} */}
          {sections.map((section, index) => (
            <div key={index}>
              <h1 className="text-[#C9BDAA] text-[1rem] font-semibold mb-6">
                {section.heading}
              </h1>
              {section.heading === "Logo" ? (
                // <div className="logo-container">
                <img src={section.image} className="logo-image" alt="Logo" />
              ) : (
                // </div>
                <ul className="leading-7">
                  {section?.list?.map((item, itemIndex) => (
                    <motion.li
                      variants={fadeAnimation}
                      initial="initial"
                      whileInView="animate"
                      viewport={{
                        once: true,
                      }}
                      custom={itemIndex}
                      key={itemIndex}
                      className="text-white py-2 cursor-pointer"
                    >
                      {item}
                    </motion.li>
                  ))}
                </ul>
              )}
            </div>
          ))}

          <div>
            <h1 className="text-[#C9BDAA] text-[1rem] font-semibold">
              Social Links
            </h1>
            <div className="flex gap-4 mt-8 cursor-pointer">
              {footerSocialLinks?.map((item, itemIndex) => (
                // <div key={itemIndex} className={item.className}>
                //   {item.icon}
                // </div>

                <motion.li
                  variants={fadeAnimation}
                  initial="initial"
                  whileInView="animate"
                  viewport={{
                    once: true,
                  }}
                  custom={itemIndex}
                  key={itemIndex}
                  className={item.className}
                  onClick={() => {
                    if (item.link) {
                      window.open(item.link, "_blank");
                    }
                  }}
                >
                  {item.icon}
                </motion.li>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="h-px w-full bg-[#D2D2D2]"></div>
      <p className="text-center text-white text-[12px] p-5">
        Copyright ® 2023. Developed & Designed by Nikhil
      </p>
    </footer>
  );
};

export default Footer;
