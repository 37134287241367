import React, { useEffect } from "react";
import Navbar from "../Components/Navbar";
// import Brands from "../AboutPageData/Brands";
import Hero from "../AboutPageData/Hero";
// import TeamMembers from "../AboutPageData/TeamMembers";
import SubscribeLetter from "../Components/SubscribeLetter";
import Footer from "../Components/Footer";
import ProjectContainer from "../AboutPageData/ProjectContainer";
// import { CardDetails } from "../utils/CardsData";

const About = () => {
  // useEffect(() => {
  //   if (window.pageYOffset > 0) {
  //     window.scrollTo({ top: 0, behavior: "smooth" });
  //   }
  // }, []);
  return (
    <div>
      <Navbar />
      <Hero />
      <ProjectContainer />
      <Footer />
    </div>
  );
};

export default About;
