import { TextField } from "@mui/material";
import React from "react";

export const DynamicTextField = (props) => {
  const { name, id, onChange, label, error, helperText, multiline, rows } =
    props;
  return (
    <div className="my-5">
      <TextField
        name={name}
        fullWidth
        id={id}
        label={label}
        variant="outlined"
        error={error}
        multiline={multiline}
        rows={rows}
        onChange={onChange}
      />
    </div>
  );
};
