import React, { useState, useRef } from "react";
import Button from "./Button";
import icon from "../assests/letter-x.gif";
import { DynamicTextField } from "./DynamicTextField";
import emailjs from "@emailjs/browser";
import { showSnackbar } from "../utils/snackbarUtils";

const SideBar = ({ showBtn }) => {
  const [isOpen, setIsopen] = useState(false);
  const [error, setError] = useState({
    nameText: false,
    emailText: false,
    phoneText: false,
    messageText: false,
  });
  const formRef = useRef();

  const ToggleSidebar = () => {
    setIsopen(!isOpen);
    if (!isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  };

  const handleInputChange = (fieldName) => {
    console.log("field", fieldName);
    setError((prevError) => ({
      ...prevError,
      [fieldName]: false,
    }));
  };
  const sendEmail = (e) => {
    e.preventDefault();

    const formFields = formRef.current.elements;
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const phoneRegex = /^\d{10}$/;

    const updatedErrors = {
      nameText: formFields.user_name.value === "",
      // emailText: formFields.user_email.value === "",
      // phoneText: formFields.user_phone.value === "",
      emailText: !emailRegex.test(formFields.user_email.value), // Validate email
      phoneText: !phoneRegex.test(formFields.user_phone.value),
      messageText: formFields.user_message.value === "",
    };

    setError(updatedErrors);

    if (
      !(
        updatedErrors.nameText ||
        updatedErrors.emailText ||
        updatedErrors.phoneText ||
        updatedErrors.messageText
      )
    ) {
      emailjs
        .sendForm(
          "service_2a4j54i",
          "template_rvwk70t",
          formRef.current,
          "ckkAywP8pGUiACdvI"
        )
        .then(
          (result) => {
            console.log("res", result);
            if (result.status === 200) {
              formRef.current.reset();
              showSnackbar("Email sent successfully!");
            }
          },
          (error) => {
            console.log(error.text);
          }
        );
    }
  };
  return (
    <>
      {showBtn ? (
        <Button
          className="service-btn mt-2 px-[48px] py-[15px] bg-white z-10 rounded-3xl text-[#AE8C64]"
          handleClick={ToggleSidebar}
          type="button"
        >
          Book Services
        </Button>
      ) : (
        <Button
          type="button"
          className="service-btn border z-10 border-[#AE8C64] mt-2 px-[50px] py-[15px] rounded-3xl text-[#AE8C64]"
          handleClick={ToggleSidebar}
        >
          Get In Touch
        </Button>
      )}

      <div
        className={`sidebar ${
          isOpen == true ? "active" : ""
        } sm:w-full md:w-2/3 lg:w-1/2 xl:w-[450px]`}
      >
        <div id="custom-snackbar"></div>
        <div className="sd-header">
          <h2 className="mb-0 contact_heading">CONTACT US</h2>
          <div onClick={ToggleSidebar}>
            <img
              className="cursor-pointer	 w-[2.2rem] h-[2.2rem]"
              src={icon}
            ></img>
          </div>
        </div>
        <div className="sd-body">
          <p className="text-[#696969] p-2">
            Give us a call or fill in the form below and we will contact you. We
            endeavor to answer all inquiries within 24 hours on business days.
          </p>
          <form ref={formRef} onSubmit={sendEmail}>
            <>
              <DynamicTextField
                name="user_name"
                id="user_name"
                label="Enter Your Name"
                error={error.nameText}
                helperText={error.nameText ? "Name is required" : ""}
                onChange={() => handleInputChange("nameText")}
              ></DynamicTextField>
              <DynamicTextField
                name="user_email"
                id="user_email"
                label="Enter Your Email"
                error={error.emailText}
                helperText={error.emailText ? "Email is required" : ""}
                onChange={() => handleInputChange("emailText")}
              ></DynamicTextField>
              <DynamicTextField
                name="user_phone"
                id="user_phone"
                label="Enter Your Phone"
                error={error.phoneText}
                helperText={error.phoneText ? "Phone No is required" : ""}
                onChange={() => handleInputChange("phoneText")}
              ></DynamicTextField>
              <DynamicTextField
                multiline
                rows="6"
                name="user_message"
                id="user_message"
                label="Enter Your Message"
                error={error.messageText}
                helperText={error.messageText ? "Message is required" : ""}
                onChange={() => handleInputChange("messageText")}
              ></DynamicTextField>
            </>

            <Button
              className="w-full mt-2 px-[48px] py-[15px] bg-[#AE8C64] z-10 rounded-3xl text-white"
              type="submit"
              // text="Submit"
            >
              Submit
            </Button>
          </form>
        </div>
      </div>
      <div
        className={`sidebar-overlay ${isOpen == true ? "active" : ""}`}
        onClick={ToggleSidebar}
      ></div>
    </>
  );
};

export default SideBar;
