import React from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import ServiceHeader from "../assests/serivces_page.webp";
import image1 from "../assests/service_leaf1.webp";
import image2 from "../assests/service1.webp";
import image3 from "../assests/service2.webp";
import image4 from "../assests/service3.webp";
import image5 from "../assests/service_leaf.jpg";

export const Services = () => {
  return (
    <>
      <Navbar />
      <>
        <div className="parentContainer">
          <img src={ServiceHeader} className="w-full h-auto" />
          <div className="centered">
            <h2 className=" service-title text-4xl md:text-6xl lg:text-7xl xl:text-9xl">
              Our Services
            </h2>
          </div>
        </div>
        <div>
          <div className="service-container">
            <h2 className="service-title2 xl:text-[70px] sm:text-[40px] md:text-[50px] lg:text-[60px]">
              We love what we do
            </h2>
          </div>
          <div className="mt-[3rem]">
            <div className="flex flex-wrap justify-evenly items-center mt-[3rem] m-4">
              <div>
                <img src={image1} />
                {/* <h4 className="font-semibold m-1 font-[1.5rem]">LET'S MEET</h4> */}
                <h3 className="service-title3">WEDDING PLANNING</h3>
                <p className="service-description sm:mb-6">
                  At The Event Code, we take the hassle out of finding the{" "}
                  <br className="break-para1" />
                  perfect venue for your special day. From picturesque{" "}
                  <br className="break-para1" />
                  outdoor settings to elegant ballrooms, we scout and{" "}
                  <br className="break-para1" /> secure venues that align with
                  your vision and budget.
                </p>
              </div>
              <div>
                <img src={image2} />
              </div>
            </div>
            <div className="flex flex-wrap justify-evenly items-center mt-[3rem] m-4">
              <div className="order-2 lg:order-1">
                <img src={image3} />
              </div>
              <div className=" order-1 lg:order-2">
                {/* <h4 className="font-semibold m-1 font-[1.5rem]">WE’RE THERE</h4> */}
                <h3 className="service-title3 lg:mt-8">CORPORATE EVENTS</h3>
                <p className="service-description sm:mb-6">
                  Let The Event Code elevate your corporate gatherings to new
                  <br className="break-para2" />
                  heights with our expert event planning services.From venue
                  <br className="break-para2" />
                  selection and logistics coordination to audiovisual setup and
                  <br className="break-para2" />
                  catering arrangements.
                </p>
              </div>
            </div>
            <div className="flex  flex-wrap justify-evenly items-center mt-[3rem] m-4">
              <div>
                <h3 className="service-title3">DECOR PLANNING</h3>
                <p className="service-description sm:mb-6">
                  Transform your wedding or event into an enchanting{" "}
                  <br className="break-para3" /> masterpiece with our meticulous
                  decor planning services.
                  <br className="break-para3" />
                  From elegant floral arrangements and captivating centerpieces
                  <br className="break-para3" />
                  to intricate lighting designs and stylish furniture rentals.
                </p>
              </div>
              <div>
                <img src={image4} />
                <img
                  src={image5}
                  className="ml-[-6rem] mt-[-6rem] mb-2 sm:hidden md:block"
                />
              </div>
            </div>
          </div>
        </div>
      </>
      <Footer />
    </>
  );
};
