import React from "react";
import image from "../assests/Friend.webp";
import image1 from "../assests/raffles.webp";
import image2 from "../assests/logo2.webp";

const ProjectContainer = () => {
  const data = [
    {
      img: image,
      title: "PROFESSIONAL TEAM AND CREWS",
      description:
        "Our professional team and crews are the backbone of every successful event we organize.",
    },
    {
      img: image1,
      title: "VENUE BOOKINGS",
      description:
        "Secure the perfect venue for your event with our comprehensive venue booking services.",
    },
    {
      img: image2,
      title: "AMAZING WEDDING DECORATION",
      description:
        " From enchanting floral arrangements to stunning lighting designs,transforming venues into breathtaking spaces .",
    },
  ];
  const BoxItem = ({ img, title, description }) => (
    <div className="box-item">
      <img className="rounded-md" src={img} loading="lazy" />
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  );
  return (
    <>
      <div className="bg-[#42413D]">
        <div className="py-[100px]">
          <h2 className="about-header lg:text-[45px] text-center mb-6">
            WHY CHOOSE US
          </h2>
          <div className="box-container ">
            {data.map((item, index) => (
              <BoxItem
                key={index}
                img={item.img}
                title={item.title}
                description={item.description}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectContainer;
