import React from "react";
import image7 from "../assests/DECOR 2023_386.webp";
import { Zoom } from "react-awesome-reveal";

const ProjectContainer = () => {
  return (
    <>
      <div
        className={`flex lg:gap-10 xl:gap-[5rem] gap-[2rem] justify-center flex-wrap xl:mt-20 mt-16`}
      >
        <div className="m-6">
          <h1 className="main-heading2 text-[2rem] text-[#1e266d]">
            PREMIER EVENT PLANNING,
            <br className="break-desktop4" /> DESIGN, AND MANAGEMENT
          </h1>
          <p className="mt-2 text-[#515151] text-[1rem] font-semibold py-8 ">
            We are a dynamic team that has been producing luxury{" "}
            <br className="break-desktop5" /> events for over 7+ years. Our
            expertise and relationships <br className="break-desktop5" />
            are the foundation of a well planned event, but passion is
            <br className="break-desktop5" /> the driving force behind
            everything we do.
          </p>
          {/* <Button
            className="mt-2 px-[48px] py-[15px] text-white rounded-md bg-[#AE8C64]"
            type="button"
          >
            Discover More
          </Button> */}
        </div>
        <div>
          <Zoom triggerOnce>
            <img
              src={image7}
              className=" xl:w-[28rem] w-[23rem] xl:m-[0.5rem] rounded-lg"
            />
          </Zoom>
        </div>
      </div>
    </>
  );
};

export default ProjectContainer;
