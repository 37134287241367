import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { BiPlus, BiMinus } from "react-icons/bi";
import AccImage from "../assests/gall/Krishna.jpeg";

function CustomAccordion({ expanded, onChange, panelId, title, content }) {
  return (
    <Accordion
      className="accordion mt-2"
      expanded={expanded === panelId}
      onChange={onChange(panelId)}
    >
      <AccordionSummary
        className="accordion-summary"
        expandIcon={expanded === panelId ? <BiMinus /> : <BiPlus />}
        aria-controls={`${panelId}bh-content`}
        id={`${panelId}bh-header`}
      >
        <Typography className="text-[#010101] italic py-[15px] faq-title">
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className="text-[#7A7A7A] accordion-details">
        <Typography>{content}</Typography>
      </AccordionDetails>
    </Accordion>
  );
}

const Hero = () => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const accordionData = [
    {
      panelId: "panel1",
      title: "How much does a destination wedding cost ?",
      content:
        "A normal destination wedding cost in Udaipur is around Rs 20-30 Lakh",
    },
    {
      panelId: "panel2",
      title: "What all services do we offer ?",
      content:
        "We usually plan whole wedding,decor,accomodation & transportation for our beloved guests. We also book venues such as wedding reception,cocktail,mehendi etc.",
    },
    {
      panelId: "panel3",
      title: "Where are we based from ?",
      content:
        "We are a local based company in Udaipur.We have 7+ years of experience in this industry and have done 50+ weddings",
    },
    {
      panelId: "panel4",
      title: "What are the types of weddings we conduct ?",
      content:
        "The Event Code offer a variety of wedding styles to cater to different preferences and cultural backgrounds. Some of the types of weddings we conduct include traditional ceremonies, destination weddings, elopements, themed weddings etc",
    },
    {
      panelId: "panel5",
      title: "What locations do we work in ?",
      content:
        "We work in various location near udaipur in the radius of around 300-400 KM. Jaipur,Jodhpur,Jaisalmer,Kumbhalgarh,Ranakpur are the major locations which we have covered",
    },
  ];

  return (
    <div
      className={`about-container flex flex-wrap sm:gap-[2rem] xl:gap-[2rem] lg:gap-[2rem]  xl:m-[2.5rem] lg:m-[1rem]`}
    >
      <div className={`flex justify-center px-4 w-full lg:w-1/2`}>
        <img
          src={AccImage}
          // loading="lazy"
          className="object-cover rounded-md xl:h-[45rem] xl:w-[36rem]"
        />
      </div>
      <div className={`w-full lg:w-1/2 mx-4`}>
        <div className="main-heading3">
          <img
            className="w-[36px]"
            src="https://askproject.net/wedda/wp-content/uploads/sites/149/2023/07/iconAsset-3.png"
          ></img>
          <h1 className="xl:text-[44px] sm:text-[40px] mt-4 text-[#010101]">
            FREQUENTLY ASKED <br />
            QUESTIONS
          </h1>
          <p className="text-[20px] font-bold italic text-[#7A7A7A] mt-6 mb-4">
            The Event Code is here to help you figure out what you need.
          </p>
          {accordionData.map((item) => (
            <CustomAccordion
              key={item.panelId}
              expanded={expanded}
              onChange={handleChange}
              {...item}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Hero;
