import React from "react";
import { Zoom } from "react-awesome-reveal";
import SideBar from "./SideBar";
import image1 from "../assests/wedding-icon6.png";
import image2 from "../assests/wedding-icon7.png";
import image3 from "../assests/wedding-icon8.png";
import image4 from "../assests/wedding-icon9.png";
import image5 from "../assests/wedding-icon10.png";
import image6 from "../assests/wedding-icon11.png";

const SubscribeLetter = () => {
  const gridItems = [
    {
      img: image1,
      title: "Wedding Decor",
      //"Expertly planning and coordinating all of your special occasions.",
    },
    {
      img: image2,
      title: "Destination Wedding",
      //"Expertly planning and coordinating all of your special occasions.",
    },
    {
      img: image3,
      title: " Corporate Events",
      //"Expertly planning and coordinating all of your special occasions.",
    },
    {
      img: image4,
      title: "Birthday / Surprise Party",
      //"Expertly planning and coordinating all of your special occasions.",
    },
    {
      img: image5,
      title: " Wedding Management ",
      //"Expertly planning and coordinating all of your special occasions.",
    },
    {
      img: image6,
      title: "Venue Management",
      //"Expertly planning and coordinating all of your special occasions.",
    },
  ];
  return (
    <div className="bg-[#faefed] lg:mt-[4rem] ">
      <div className="service-heading pt-[3rem]">
        <h2 className="text-[6rem]">Our Services</h2>
        <p className="text-[2rem] text-center ">
          WE OFFER VARIETY OF SERVICES IN UDAIPUR CITY
        </p>
      </div>
      <div className="flex mt-[5rem] justify-center">
        <div className="mb-[2rem] m-[1rem] grid sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-10 rounded-lg place-items-center">
          {gridItems.map((item, index) => (
            <Zoom damping={0.1} cascade triggerOnce>
              <div
                key={index}
                className="flex flex-col items-center gap-[2rem] p-[3rem] xl:w-[24rem] py-[4rem] justify-center card-div rounded-lg	bg-white hover:shadow-xl"
              >
                <img src={item.img}></img>
                <h2 className="text-[#1e266d] text-[22px] text-center">
                  {item.title}
                </h2>
                {/* <p className="text-center text-[#455880]">{item.content}</p> */}
              </div>
            </Zoom>
          ))}
        </div>
      </div>
      <div className=" flex border-black justify-center py-[4rem]">
        <SideBar showBtn={false} />
      </div>
    </div>
  );
};

export default SubscribeLetter;

{
  /* <div className=" md:flex">
  <input
    type="email"
    placeholder="Enter email address"
    className="w-full px-12  py-2 rounded-lg text-[14px]"
  />
  <button className="bg-[#DC7000] text-white rounded-lg px-8 z-10">
    Continue
  </button>
</div> */
}
