import React from "react";

const Button = ({ text, type, handleClick, className, children }) => {
  return (
    <button className={className} type={type} onClick={handleClick}>
      {children}
    </button>
  );
};

export default Button;
