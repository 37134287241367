import React, { useRef } from "react";
import Navbar from "../Components/Navbar";
import { useState } from "react";
import {
  TextField,
  Typography,
  MenuItem,
  Select,
  FormControl,
  FormHelperText,
} from "@mui/material";
import emailjs from "@emailjs/browser";
import { showSnackbar } from "../utils/snackbarUtils";
import Button from "../Components/Button";

export default function Contact() {
  const [error, setError] = useState({
    nameText: false,
    emailText: false,
    messageText: false,
    budgetText: false,
  });
  const form = useRef();
  const [selectedBudget, setSelectedBudget] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();

    const formFields = form.current.elements;
    const updatedErrors = {
      nameText: formFields.user_name.value === "",
      emailText: formFields.user_email.value === "",
      messageText: formFields.message.value === "",
      budgetText: formFields.user_budget.value === "",
    };

    setError(updatedErrors);

    if (
      !(
        updatedErrors.nameText ||
        updatedErrors.emailText ||
        updatedErrors.messageText ||
        updatedErrors.budgetText
      )
    ) {
      emailjs
        .sendForm(
          "service_2a4j54i",
          "template_rvwk70t",
          form.current,
          "ckkAywP8pGUiACdvI"
        )
        .then(
          (result) => {
            console.log("res", result);
            if (result.status === 200) {
              showSnackbar("Email sent successfully!");
              e.target.reset();
              setSelectedBudget("");
            }
          },
          (error) => {
            console.log(error.text);
          }
        );
    }
  };
  const handleInputChange = (fieldName) => {
    console.log("field", fieldName);
    setError((prevError) => ({
      ...prevError,
      [fieldName]: false,
    }));
  };
  const contactData = [
    {
      title: "Address",
      description: "Street number and town name 23",
    },
    {
      title: "Phone",
      description: "+91 774-2753-888",
    },
    {
      title: "E-mail",
      description: "theeventcode30@gmail.com",
    },
    {
      title: "Working hours",
      description: "Mon - Fri 11AM - 10PM",
    },
  ];
  const priceRange = [
    "3,19,800 - 6,39,600 INR",
    "6,39,600 - 12,79,200 INR",
    "12,79,200 - 25,58,400 INR",
    "25,58,400 - 51,16,800 INR",
    "51,16,800 - 1,03,93,500 INR",
    "1,03,93,500 - 2,07,87,000 INR",
    "2,07,87,000 - 4,15,74,000 INR",
  ];
  return (
    <>
      <Navbar />
      <div id="custom-snackbar"></div>

      <div className="flex flex-wrap lg:flex-nowrap xl:justify-around sm:gap-[2rem] xl:gap-[4rem] xl:m-[4rem] lg:m-[2rem]">
        <div className="w-full lg:w-1/2 px-4">
          <h1 className="text-[3rem] text-[#d0a37c] home_heading">
            Send us a message
          </h1>
          <p>Please fill the form below</p>
          <form
            ref={form}
            onSubmit={sendEmail}
            className="flex flex-wrap -mx-2 mt-[2rem]"
          >
            <div className="w-full md:w-1/2 px-2">
              <div className="flex items-center gap-[0.5rem]">
                <Typography className="text-[#3f3f3f] contact-typo">
                  Name
                </Typography>
                {/* ... */}
              </div>
              <TextField
                className="w-full"
                name="user_name"
                margin="normal"
                variant="outlined"
                error={error.nameText}
                helperText={error.nameText ? "Name is required" : ""}
                onChange={() => handleInputChange("nameText")}
              />
            </div>
            <div className="w-full md:w-1/2 px-2">
              <div className="flex items-center gap-[0.5rem]">
                <Typography className="contact-typo">Email</Typography>
                {/* ... */}
              </div>
              <TextField
                className="w-full "
                name="user_email"
                margin="normal"
                variant="outlined"
                type="email"
                error={error.emailText}
                helperText={error.emailText ? "Email is required" : ""}
                onChange={() => handleInputChange("emailText")}
              />
            </div>
            <div className="w-full px-2">
              <div className="flex items-center gap-[0.5rem]">
                <Typography className="contact-typo">Message</Typography>
              </div>
              <TextField
                fullWidth
                name="message"
                margin="normal"
                variant="outlined"
                multiline
                rows={6}
                error={error.messageText}
                helperText={error.messageText ? "Message is required" : ""}
                onChange={() => handleInputChange("messageText")}
              />
            </div>
            <div className="w-full px-2 mt-2">
              <div className="flex items-center gap-[0.5rem]">
                <Typography className="contact-typo">Project Budget</Typography>
              </div>
              <FormControl fullWidth error={error.budgetText}>
                <Select
                  className="mt-4"
                  name="user_budget"
                  value={selectedBudget}
                  onChange={(e) => {
                    setSelectedBudget(e.target.value);
                    handleInputChange("budgetText");
                  }}
                >
                  {priceRange.map((range, index) => (
                    <MenuItem key={index} value={range}>
                      {range}
                    </MenuItem>
                  ))}
                </Select>
                {error.budgetText && (
                  <FormHelperText>This Field is required</FormHelperText>
                )}
              </FormControl>
            </div>
            <div className="w-full px-2 mt-8">
              <Button
                type="submit"
                className="px-[30px] py-[15px] font-bold text-white bg-[#C18A54]"
              >
                SEND MESSAGE
              </Button>
            </div>
          </form>
        </div>
        <div className="w-full lg:w-1/2 px-4 mt-8 sm:mt-0">
          <h1 className="text-[3rem] text-[#d0a37c] home_heading">
            Contact information
          </h1>
          <p>
            We endeavor to answer all inquiries within 24 hours on business
            days.
          </p>
          <div className="mt-[3rem] grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 gap-10 rounded-lg">
            {contactData.map((item, index) => (
              <div key={index}>
                <h2 className="text-[#c18a54] contact-typo">{item.title}</h2>
                <p className="text-[#455880] ">{item.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
