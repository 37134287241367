import React, { useEffect, useState } from "react";
import Navbar from "../Components/Navbar";

import { IoIosCloseCircle } from "react-icons/io";

import { galleryData } from "../utils/galleryData";
import Footer from "./../Components/Footer";

const Gallery = () => {
  const [images, setImages] = useState(galleryData);
  // const images = [
  //   {
  //     img: image1,
  //     style: {
  //       width: "100%",
  //       height: "450px",
  //       objectFit: "cover",
  //     },
  //     title: "ROBBIE & MAGGIE",
  //     genre: "Wedding Dress",
  //     margin: "0px 40px 0px 0px",
  //   },
  //   {
  //     img: image2,
  //     style: {
  //       width: "100%",
  //       height: "235px",
  //       objectFit: "cover",
  //     },
  //     margin: "0px 20px 0px 20px",
  //     title: "BARGIE & JULIA",
  //     genre: "Wedding Dress",
  //   },
  //   {
  //     img: image3,
  //     style: {
  //       width: "100%",
  //       height: "450px",
  //       objectFit: "cover",
  //     },
  //     title: "ALBERT & MIYA ",
  //     genre: "Photography      ",
  //     margin: "0px 0px 0px 40px",
  //   },
  //   {
  //     img: image4,
  //     style: {
  //       width: "100%",
  //       height: "235px",
  //       objectFit: "cover",
  //       marginTop: "50px",
  //     },
  //     margin: "0px 40px 0px 0px",
  //     title: "PETER & EMMA",
  //     genre: "Wedding Dress",
  //   },
  //   {
  //     img: image5,
  //     style: {
  //       width: "100%",
  //       height: "440px",
  //       objectFit: "cover",
  //       marginTop: "50px",
  //     },
  //     margin: "-205px 20px 0px 20px",
  //     title: "TONY & LUISA",
  //     genre: "Wedding Dress",
  //   },
  //   {
  //     img: image6,
  //     style: {
  //       width: "100%",
  //       height: "235px",
  //       objectFit: "cover",
  //       marginTop: "50px",
  //     },
  //     margin: "0px 0px 0px 40px",
  //     title: "FELIX & MARZIA",
  //     genre: "Photography",
  //   },
  //   {
  //     img: image7,
  //     style: {
  //       width: "100%",
  //       height: "450px",
  //       objectFit: "cover",
  //       marginTop: "2.5rem",
  //     },
  //     title: "ROBBIE & MAGGIE",
  //     genre: "Wedding Dress",
  //     margin: "0px 40px 0px 0px",
  //   },
  //   {
  //     img: image8,
  //     style: {
  //       width: "100%",
  //       height: "235px",
  //       objectFit: "cover",
  //       marginTop: "2.5rem",
  //     },
  //     margin: "0px 20px 0px 20px",
  //     title: "BARGIE & JULIA",
  //     genre: "Wedding Dress",
  //   },
  //   {
  //     img: image9,
  //     style: {
  //       width: "100%",
  //       height: "450px",
  //       objectFit: "cover",
  //       marginTop: "2.5rem",
  //     },
  //     title: "ALBERT & MIYA ",
  //     genre: "Photography      ",
  //     margin: "0px 0px 0px 40px",
  //   },
  //   {
  //     img: image10,
  //     style: {
  //       width: "100%",
  //       height: "235px",
  //       objectFit: "cover",
  //       marginTop: "50px",
  //     },
  //     margin: "0px 40px 0px 0px",
  //     title: "PETER & EMMA",
  //     genre: "Wedding Dress",
  //   },
  //   {
  //     img: image11,
  //     style: {
  //       width: "100%",
  //       height: "440px",
  //       objectFit: "cover",
  //       marginTop: "50px",
  //     },
  //     margin: "-205px 20px 0px 20px",
  //     title: "TONY & LUISA",
  //     genre: "Wedding Dress",
  //   },
  //   {
  //     img: image12,
  //     style: {
  //       width: "100%",
  //       height: "235px",
  //       objectFit: "cover",
  //       marginTop: "50px",
  //     },
  //     margin: "0px 0px 0px 40px",
  //     title: "FELIX & MARZIA",
  //     genre: "Photography",
  //   },
  // ];

  const [modalVisible, setModalVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const getImage = (imgSrc) => {
    setSelectedImage(imgSrc);
    setModalVisible(true);
  };

  useEffect(() => {
    const screenWidth = Math.max(
      window.innerWidth,
      document.documentElement.clientWidth
    );

    const isSmallScreen = screenWidth >= 375 && screenWidth < 950;
    const isMediumScreen = screenWidth >= 950 && screenWidth < 1140;
    const equalHeight = isSmallScreen ? "400px" : "";
    const equalMargin =
      isSmallScreen || isMediumScreen ? "0px 10px 0px 10px" : null;
    const marginTop = isSmallScreen ? "0px" : null;

    setImages((prevImages) => {
      return prevImages.map((image) => {
        return {
          ...image,
          style: {
            ...image.style,
            height: equalHeight || image.style.height,
            marginTop: marginTop || image.style.marginTop,
          },
          margin: equalMargin || image.margin,
        };
      });
    });
  }, []);

  return (
    <>
      <Navbar />
      <h2 className="gallery-heading">Gallery</h2>
      <div className={modalVisible ? "model open" : "model"}>
        <img className="model-img" src={selectedImage}></img>
        <IoIosCloseCircle
          className="model-close-icon"
          onClick={() => setModalVisible(false)}
        />
      </div>
      <div className="gallery-container">
        {images.map((image, index) => (
          <div key={index} className="gallery-item">
            <div
              className="img-div"
              onClick={() => getImage(image.img)}
              style={{ margin: image.margin }}
            >
              <div>
                <img
                  style={image.style}
                  src={image.img}
                  alt={image.title}
                  // loading="lazy"
                />
              </div>
              <div>
                <h5 className="gallery-title">{image.title}</h5>
                <p className="gallery-genre">{image.genre}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Footer />
    </>
  );
};

export default Gallery;
