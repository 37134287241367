import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./Css/App.css";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import ScrollToTopButton from "./utils/scrollTop";
import ErrorPage from "./Pages/ErrorPage";
import { WavyContainer } from "react-wavy-transitions";
import Gallery from "./Pages/Gallery";
import { Services } from "./Pages/Services";

function App() {
  const routes = [
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/about",
      element: <About />,
    },
    {
      path: "/gallery",
      element: <Gallery />,
    },
    {
      path: "/contact",
      element: <Contact />,
    },
    {
      path: "/services",
      element: <Services />,
    },
    {
      path: "*",
      element: <ErrorPage />,
    },
  ];

  return (
    <div className="App">
      <Router>
        <WavyContainer />
        <Routes>
          {routes?.map((route, index) => {
            const { path, element } = route;
            return <Route key={index} path={path} element={element}></Route>;
          })}
        </Routes>
      </Router>
      <ScrollToTopButton />
    </div>
  );
}

export default App;
