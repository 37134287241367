import React from "react";
import Navbar from "../Components/Navbar";
import Hero from "../Components/Hero";
import Testimonials from "../Components/Testimonials";
import ProjectContainer from "../Components/ProjectContainer";
import SubscribeLetter from "../Components/SubscribeLetter";
import Footer from "../Components/Footer";
// import { Fade } from "react-awesome-reveal";

const Home = () => {
  // useEffect(() => {
  //   if (window.pageYOffset > 0) {
  //     window.scrollTo({ top: 0, behavior: "smooth" });
  //   }
  // }, []);

  return (
    <>
      <Navbar />
      {/* <div className="mb-[500px] relative z-[10]"> */}
      <div>
        <Hero />
        <Testimonials />
        <ProjectContainer />
        <SubscribeLetter />
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
};

export default Home;
