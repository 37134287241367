import React from "react";
import { NavLink } from "react-router-dom";

export default function ErrorPage() {
  return (
    <>
      <NavLink to="/">
        <button type="submit">Back to HomePage</button>
      </NavLink>
    </>
  );
}
