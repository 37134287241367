// import React from "react";
import { TiSocialFacebook, TiSocialTwitter } from "react-icons/ti";
import { IoLogoInstagram } from "react-icons/io";
import { GrLinkedinOption } from "react-icons/gr";
import image from "../assests/website_logo-removebg-preview.png";
import { Link } from "react-router-dom";
// const iconClassNames = {
//   meta: "flex justify-center items-center bg-white rounded-full text-black w-[2rem] px-2 h-8 meta",
//   instagram:
//     "flex justify-center items-center bg-white rounded-full text-black w-[2rem] px-2 h-8 instagram",
//   twitter:
//     "flex justify-center items-center bg-white rounded-full text-black w-[2rem] px-2 h-8 twitter",
//   linkedin:
//     "flex justify-center items-center bg-white rounded-full text-black w-[2rem] px-2 h-8 linkedin",
// };

// const generateIcon = (IconComponent, className) => (
//   <IconComponent className={className} />
// );

// export const footerSocialLinks = [
//   {
//     icon: generateIcon(TiSocialFacebook, iconClassNames.meta),
//   },
//   {
//     icon: generateIcon(IoLogoInstagram, iconClassNames.instagram),
//   },
//   {
//     icon: generateIcon(TiSocialTwitter, iconClassNames.twitter),
//   },
//   {
//     icon: generateIcon(GrLinkedinOption, iconClassNames.linkedin),
//   },
// ];

// export default iconClassNames;
const iconClassNames =
  "flex justify-center items-center bg-white rounded-full text-black w-[2rem] px-2 h-8";

export const footerSocialLinks = [
  { icon: <TiSocialFacebook />, className: `${iconClassNames} meta` },
  {
    icon: <IoLogoInstagram />,
    className: `${iconClassNames} instagram`,
    link: "https://www.instagram.com/theeventcode/",
  },
  { icon: <TiSocialTwitter />, className: `${iconClassNames} twitter` },
  { icon: <GrLinkedinOption />, className: `${iconClassNames} linkedin` },
];

export const sections = [
  {
    heading: "Logo",
    image: image,
    list: [
      <>
        Lorem Ipsum is simply dummy text of the <br />
        printing and typesetting industry.
      </>,
      "info@company.com",
    ],
  },
  {
    heading: "Contact us",
    list: [
      "Location : Udaipur, Rajasthan 313001",
      // "Phone : +91 774-2753-888",
      <div>
        <span>Phone :</span> <a href="tel:+917742753888">+91 774-2753-888</a>
      </div>,
      <a href="mailto:theeventcode30@gmail.com">
        Email : theeventcode30@gmail.com
      </a>,
    ],
  },
  {
    heading: "Support",
    list: [
      <Link to="/about">About us</Link>,
      <Link to="/gallery">Portfolio</Link>,
      <Link to="/services">Services</Link>,
      <Link to="/contact">Contact us</Link>,
    ],
  },
];
